import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row overview" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-4" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkillColor = _resolveComponent("SkillColor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('skills.personal')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SkillColor, {
            value: _ctx.overview.personal.val,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick('personal')))
          }, null, 8, ["value"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('skills.social')), 1),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_SkillColor, {
            value: _ctx.overview.social.val,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick('social')))
          }, null, 8, ["value"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('skills.professional')), 1),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_SkillColor, {
            value: _ctx.overview.working.val,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClick('working')))
          }, null, 8, ["value"])
        ])
      ])
    ])
  ]))
}