
import { Emitter } from 'mitt';
import { computed, defineComponent, inject, nextTick, ref, PropType } from 'vue';

import { useStore } from 'vuex';

export type OverviewResult = {
  social: { sum: number; count: number; val: number };
  personal: { sum: number; count: number; val: number };
  working: { sum: number; count: number; val: number };
};

export default defineComponent({
  props: {
    overview: { type: Object as PropType<OverviewResult>, required: true },
  },

  setup(props) {
    const store = useStore();
    const loading = ref(true);

    const mitt = inject('emitter') as Emitter<{ open: string }>;

    // if (props.type == 'company') {
    //   store.dispatch('fetchOverviewGrades').then(() => {
    //     loading.value = false;
    //   });
    // } else if (props.type == 'unit') {
    //   store.dispatch('fetchOverviewGrades', props.unit).then(() => {
    //     loading.value = false;
    //   });
    // }

    // const overview = computed(() => store.getters.getOverviewSkills || false);

    const onClick = (to: 'personal' | 'social' | 'working') => {
      const element = document.getElementById(to);
      mitt.emit('open', to);
      nextTick(() => {
        window.scrollTo({
          top: (element?.offsetTop || 0) - 20,
          behavior: 'smooth',
        });
      });
    };

    return {
      onClick,
      loading,
    };
  },
});
