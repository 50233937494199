
import { computed, defineComponent, onActivated, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Accordion from '@/components/util/Accordion.vue';
import CompareDialogue from '@/components/CompareDialogue.vue';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { SkillRecord } from './Personal.vue';
import Overview, { OverviewResult } from '@/components/Overview.vue';

export default defineComponent({
  components: { Accordion, CompareDialogue, Overview },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    const hovered = ref(-1);
    const sliceoffset = ref(0);
    const table = ref<Array<any>>([]);
    const labels = ref<Array<any>>([]);
    const unitid = ref<undefined | number>(parseInt(route.params?.id?.toString()) || undefined);
    const compareOpen = ref(false);
    const overviewHeader = ref('');
    const tableHeader = ref('');
    const fixed = true;
    const skills = ref<Array<SkillRecord>>([]);
    const overview = ref<OverviewResult>();
    const headline = ref('');

    const compareClick = () => (compareOpen.value = true);
    const compareClose = () => (compareOpen.value = false);

    const type = computed(() => {
      if (unitid.value) return 'unit';
      return 'company';
    });

    const fetchData = () => {
      switch (type.value) {
        case 'unit':
          overviewHeader.value = 'Unit';
          tableHeader.value = 'Mitarbeiter:innen';
          store.dispatch('fetchUnitGrades', unitid.value).then((res) => {
            skills.value = res.meta.skills.sort(store.getters.getSort(unitid.value));
            table.value = res.result;
            labels.value = res.meta.labels;
            headline.value = `der Abteilung ${res.meta.name}`;
            loading.value = false;
            overview.value = res.overview;
            sliceoffset.value = 0;
          });
          break;
        case 'company':
          overviewHeader.value = 'Company';
          tableHeader.value = 'Abteilungen';
          store.dispatch('fetchCompanyGrades').then((res) => {
            skills.value = res.meta.skills.sort(store.getters.getSort());
            table.value = res.result;
            labels.value = res.meta.labels;
            loading.value = false;
            overview.value = res.overview;
            sliceoffset.value = 0;
          });
          break;
      }
    };

    onMounted(() => {
      watch(
        () => route.fullPath,
        () => {
          if (['OverviewBig', 'OverviewCompany', 'OverviewUnit'].includes(route.name?.toString() || '')) {
            unitid.value = parseInt(route.params?.id?.toString()) || undefined;
            fetchData();
          }
        },
        { immediate: true }
      );
    });

    const matrixLabels = computed(() => {
      const filter = store.getters.getCompareFilter;
      if (filter.length > 0) {
        return labels.value.filter((item: any) => filter.includes(item.id)) || [];
      } else {
        return labels.value || [];
      }
    });

    const filteredSkills = (type: 'personal' | 'social' | 'working') => {
      return skills.value.filter((item: SkillRecord) => item.group == type);
    };

    const slicedMatrixLabels = computed(() => matrixLabels.value?.slice(sliceoffset.value, sliceoffset.value + 6));

    const sumoffset = computed(() => {
      return 6 - (matrixLabels.value?.length || 0);
    });

    const left = () => {
      if (sliceoffset.value > 0) {
        sliceoffset.value--;
      }
    };
    const right = () => {
      if (sliceoffset.value < (matrixLabels.value?.length || 0) - 6) {
        sliceoffset.value++;
      }
    };

    const showScroller = computed(() => (matrixLabels.value?.length || 0) > 6);

    const onMouseOver = (id: number) => {
      hovered.value = id;
    };

    const onMouseLeave = () => {
      hovered.value = -1;
    };

    const navigate = (id: number) => {
      switch (type.value) {
        case 'company':
          store.dispatch('setCompareFilter', []);
          router.push({ name: 'OverviewUnit', params: { id } });
          break;
        case 'unit':
          store.dispatch('setCompareFilter', []);
          router.push({ name: 'OverviewPersonal', params: { id } });
      }
    };

    const isFuture = (skill: SkillRecord) => {
      if (unitid.value) {
        return type.value == 'unit' && skill.future.includes(unitid.value);
      } else {
        return type.value == 'company' && skill.future.length > 0;
      }
    };
    const isStrategic = (skill: SkillRecord) => {
      if (unitid.value) {
        return type.value == 'unit' && skill.strategic.includes(unitid.value);
      } else {
        return type.value == 'company' && skill.strategic.length > 0;
      }
    };

    return {
      headline,
      overview,
      table,
      isFuture,
      isStrategic,
      navigate,
      mdiArrowLeft,
      mdiArrowRight,
      types: store.getters.getTypes,
      loading,
      sumoffset,
      left,
      right,
      showScroller,
      onMouseOver,
      onMouseLeave,
      hovered,
      slicedMatrixLabels,
      matrixLabels,
      compareOpen,
      compareClick,
      compareClose,
      overviewHeader,
      tableHeader,
      fixed,
      type,
      filteredSkills,
      unitid,
    };
  },
});
